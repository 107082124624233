import { useEffect } from 'react'
import { getIsClient, getQueryParams } from '../helpers/utilities'
import { setCookie } from '../helpers/storage'
import { COUNTRY_CODE } from '../../node/functions'

const useSetLanguage = () => {
  const isClient = getIsClient()

  useEffect(() => {
    if (!isClient) return false

    const { lang } = getQueryParams()
    if (lang) {
      setCookie('nf_lang', COUNTRY_CODE, 365)
      setCookie('nf_country', COUNTRY_CODE, 365)

      window.history.pushState({}, document.title, window.location.pathname)
    }
  })
}

export { useSetLanguage }
