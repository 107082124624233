import styled, { keyframes } from 'styled-components'
import { colors, spacing } from '../../styles/utilities/variables'

const offset = '7px'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const StyledLoader = styled.div`
  width: 24px;
  height: 24px;

  position: relative;
  margin: ${spacing.large} auto;
  animation: ${rotate} 2.5s linear infinite;
`

StyledLoader.Corner = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid transparent;

  &:nth-child(1) {
    border-top-color: ${colors.primaryDarkBlue};
    left: 50%;
    transform: translateX(-50%);
    bottom: ${offset};
    width: 24px;
  }
  &:nth-child(2) {
    border-right-color: ${colors.primaryDarkBlue};
    top: 50%;
    transform: translateY(-50%);
    left: ${offset};
    height: 24px;
  }
  &:nth-child(3) {
    border-bottom-color: ${colors.primaryDarkBlue};
    left: 50%;
    transform: translateX(-50%);
    top: ${offset};
    width: 24px;
  }
  &:nth-child(4) {
    border-left-color: ${colors.primaryDarkBlue};
    top: 50%;
    transform: translateY(-50%);
    right: ${offset};
    height: 24px;
  }
`

export default StyledLoader
