import React, { useEffect, useContext } from 'react'
import { graphql } from 'gatsby'
import { parseSeo } from '../helpers/seo'
import { WpBlocksGenerator } from '../helpers/wpBlocksGenerator'
import Layout from '../views/Layout'
import SEO from '../views/Seo'
import { Breadcrumbs } from '../components/Breadcrumbs'
import { PageContent } from '../components/PageContent'
import { useSetLanguage } from '../hooks/useSetLanguage'

export default ({ data, location }) => {
  const page = data.wordpress.page
  const { blocks = [], seo } = page

  const isFrontPage =
    location && location.pathname ? location.pathname === '/' : false

  useSetLanguage()

  return (
    <Layout>
      <SEO {...parseSeo(seo)} />
      {!isFrontPage && <Breadcrumbs breadcrumbs={seo.breadcrumbs} />}
      <PageContent>
        <WpBlocksGenerator blocks={blocks} post={page} />
      </PageContent>
    </Layout>
  )
}

export const query = graphql`
  query($id: ID!) {
    wordpress {
      page(id: $id) {
        ...Single_Page
      }
    }
  }
`
