import React from 'react'
import StyledLoader from './style'

const Loader = props => {
  return (
    <StyledLoader {...props}>
      <StyledLoader.Corner />
      <StyledLoader.Corner />
      <StyledLoader.Corner />
      <StyledLoader.Corner />
    </StyledLoader>
  )
}

Loader.propTypes = {}

export { Loader }
